<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A reaction is performed at a temperature of
        <number-value :value="Temp1" unit="\text{K.}" />
        When the reaction was repeated at a temperature of
        <number-value :value="Temp2" unit="\text{K,}" />
        the rate of the reaction increased by a factor of
        <number-value :value="rateIncrease" />. What is the activation energy,
        <stemble-latex content="$\text{E}_{\text{a}},$" />
        of the reaction in
        <stemble-latex content="$\textbf{kJ/mol}?$" />
      </p>

      <calculation-input
        v-model="inputs.Ea"
        prepend-text="$\text{E}_\text{a}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question177',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ea: null,
      },
    };
  },
  computed: {
    Temp1() {
      return this.taskState.getValueBySymbol('Temp1').content;
    },
    Temp2() {
      return this.taskState.getValueBySymbol('Temp2').content;
    },
    rateIncrease() {
      return this.taskState.getValueBySymbol('rateIncrease').content;
    },
  },
};
</script>
